.read-more-btn
{
    background-color: rgb(21, 118, 236);
    font-size: 14px;
    border-radius: 20px;
    border: 1px solid rgb(21, 118, 236);
    color: white;
    padding:0.7rem;
    width:9rem;
    text-align: center;
    margin-top:2rem;
}

.read-more-btn:hover
{
    background-color: white;
    color: rgb(21, 118, 236);
    cursor:pointer;
}